// src/App.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import { FaGithub, FaEnvelope, FaChevronDown, FaLaptopCode, FaCode } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import ReactCountryFlag from 'react-country-flag';

function App() {
  // Set dark mode as default
  const [theme, setTheme] = useState('dark');
  const { t, i18n } = useTranslation();

  // Remember user preferences
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    const savedLanguage = localStorage.getItem('language');

    if (savedTheme) {
      setTheme(savedTheme);
    }

    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  // Framer Motion animations
  const pageVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -20,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  };

  // Select logo based on theme
  const menuScanLogo =
    theme === 'light'
      ? 'menuscan_logo3bg_dark.png'
      : 'menuscan_logo3bg.png';
  const crvBullsLogo = 'CRV_icon.png';
  const weTypeLogo = 'wetype.png';
  const kbfBarberShopLogo = 'kbfbarbershop.png';

  // LanguageSelector component
  function LanguageSelector({ currentLanguage, onChangeLanguage }) {
    const languages = [
      { code: 'pl', label: 'Polski', countryCode: 'PL' },
      { code: 'en', label: 'English', countryCode: 'GB' },
      // Add more languages if needed
    ];

    const selectBgColor = theme === 'light' ? 'bg-white' : 'bg-gray-800';
    const selectTextColor = theme === 'light' ? 'text-gray-900' : 'text-gray-100';

    return (
      <div className="relative">
        <select
          value={currentLanguage}
          onChange={(e) => onChangeLanguage(e.target.value)}
          className={`${selectBgColor} ${selectTextColor} border border-gray-300 dark:border-gray-600 rounded p-3 w-auto pr-20 py-1 focus:outline-none appearance-none transition-colors duration-500`}
        >
          {languages.map((lang) => (
            <option
              key={lang.code}
              value={lang.code}
              className="text-gray-900 dark:text-gray-100"
            >
              {lang.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ReactCountryFlag
            countryCode={
              languages.find((lang) => lang.code === currentLanguage)
                ?.countryCode || 'PL'
            }
            svg
            style={{
              padding: '0.125em',
              width: '1.5em',
              height: '1.5em',
            }}
            title={currentLanguage}
          />
          <FaChevronDown className="text-gray-500 dark:text-gray-400" />
        </div>
      </div>
    );
  }

  // Professional Experience component
  function ExperienceTimeline() {
    const experiences = [
      {
        date: '08.2022 - Present',
        company: 'MS-Soft (Jerzmanowa)',
        role: 'Software Developer',
        description: t('mssoft_desc'),
        icon: <FaLaptopCode className="text-white" />,
      },
      {
        date: '10.2023 - Present',
        company: 'Cross-Wave',
        role: 'Fullstack Developer',
        description: t('crosswave_desc'),
        icon: <FaCode className="text-white" />,
      },
      // Add more experiences if needed
    ];

    return (
      <section id="experience" className="mb-16">
        <motion.h2
          className="text-3xl font-bold mb-6 text-primary dark:text-secondary transition-colors duration-500"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7 }}
        >
          {t('experience')}
        </motion.h2>
        <div className="relative">
          <div className="border-l-4 border-teal-500 dark:border-teal-300 ml-4">
            {experiences.map((exp, index) => (
              <motion.div
                key={index}
                className="mb-10 ml-8 relative"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: index * 0.3 }}
              >
                {/* Timeline Icon */}
                <div className="absolute -left-10 flex items-center justify-center w-8 h-8 bg-teal-500 dark:bg-teal-300 rounded-full">
                  {exp.icon}
                </div>
                {/* Content Card */}
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                  <p className="text-sm text-gray-500 dark:text-gray-400">{exp.date}</p>
                  <h3 className="text-xl font-semibold text-teal-700 dark:text-teal-400">
                    {exp.company}
                  </h3>
                  <h4 className="text-lg text-gray-700 dark:text-gray-300">{exp.role}</h4>
                  <p className="text-gray-700 dark:text-gray-300 mt-2">{exp.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-500 overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thumb-teal-500 scrollbar-track-gray-300 dark:scrollbar-thumb-teal-400 dark:scrollbar-track-gray-700">
      {/* Header */}
      <header className="fixed w-full z-50 bg-opacity-90 backdrop-filter backdrop-blur-lg transition-colors duration-500">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <h1
              className={`text-2xl font-bold transition-colors duration-500 ${theme === 'light' ? 'text-primary' : 'text-secondary'
                }`}
            >
              Jakub Solarek
            </h1>
            <div className="flex items-center">
              <button onClick={toggleTheme} className="mr-4 text-2xl focus:outline-none">
                {theme === 'light' ? '🌙' : '☀️'}
              </button>
              <LanguageSelector
                currentLanguage={i18n.language}
                onChangeLanguage={changeLanguage}
              />
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="relative h-screen flex flex-col justify-center items-center text-center overflow-hidden">
        {/* Light Theme Background */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-teal-400 to-teal-600"
          animate={{ opacity: theme === 'light' ? 1 : 0 }}
          initial={false}
          transition={{ duration: 0.5 }}
        />
        {/* Dark Theme Background */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-teal-700 to-teal-900"
          animate={{ opacity: theme === 'light' ? 0 : 1 }}
          initial={false}
          transition={{ duration: 0.5 }}
        />
        {/* Content */}
        <div className="relative z-10">
          <motion.h2
            className="text-5xl font-bold text-white mb-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            {t('fullstack_developer')}
          </motion.h2>
          <motion.p
            className="text-xl text-gray-200 mb-8 max-w-xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {t('description')}
          </motion.p>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
          >
            <Link
              to="about"
              smooth={true}
              offset={-100}
              duration={500}
              className="px-6 py-3 bg-white text-teal-700 rounded-full font-semibold hover:bg-gray-200 cursor-pointer"
            >
              {t('about_me')}
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Main Content */}
      <motion.main
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        {/* About Me */}
        <section id="about" className="mb-16">
          <motion.h2
            className="text-3xl font-bold mb-6 text-primary dark:text-secondary transition-colors duration-500"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7 }}
          >
            {t('about_me')}
          </motion.h2>
          <motion.p
            className="text-lg leading-relaxed"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            {t('about_description')}
          </motion.p>
        </section>

        {/* Professional Experience */}
        <ExperienceTimeline />

        {/* Projects */}
        <section id="projects" className="mb-16" key={`${theme}-${i18n.language}`}>
          <motion.h2
            className="text-3xl font-bold mb-6 text-primary dark:text-secondary transition-colors duration-500"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7 }}
          >
            {t('projects')}
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                img: menuScanLogo,
                title: 'MenuScan',
                description: t('menuscan_desc'),
              },
              {
                img: crvBullsLogo,
                title: 'CRV Bulls',
                description: t('crv_desc'),
              },
              {
                img: weTypeLogo,
                title: 'TranscriptionMaster',
                description: t('tm_desc'),
              },
              {
                img: weTypeLogo,
                title: 'DM Admin',
                description: t('dm_admin_desc'),
              },
              {
                img: kbfBarberShopLogo,
                title: 'Kbf Barber Shop',
                description: t('kbf_desc'),
              },
            ].map((project, index) => (
              <motion.div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: index * 0.3 }}
              >
                <img
                  src={project.img}
                  alt={project.title}
                  className="w-full p-4 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                  <p className="text-gray-700 dark:text-gray-300">
                    {project.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Contact */}
        <section id="contact" className="mb-16">
          <motion.h2
            className="text-3xl font-bold mb-6 text-primary dark:text-secondary transition-colors duration-500"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            {t('contact')}
          </motion.h2>
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            <p className="text-lg mb-4 flex items-center">
              <FaEnvelope className="mr-2" />
              <a href="mailto:j.solarek@wp.pl" className="text-blue-500 hover:underline">
                j.solarek@wp.pl
              </a>
            </p>
            <p className="text-lg mb-4 flex items-center">
              <FaGithub className="mr-2" />
              <a href="https://github.com/novy213" className="text-blue-500 hover:underline">
                github.com/novy213
              </a>
            </p>
          </motion.div>
        </section>
      </motion.main>

      {/* Footer */}
      <footer className="bg-gray-200 dark:bg-gray-800 py-6 transition-colors duration-500">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-gray-700 dark:text-gray-300">
            © {new Date().getFullYear()} Jakub Solarek
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
